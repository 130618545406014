<template>
  <JoinComponent />
</template>

<script>
import JoinComponent from "@/components/join/JoinComponent";

export default {
  name: "JoinPage",
  props: {},
  components: {
    JoinComponent,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
